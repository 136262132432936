<template>
  <v-card flat>
    <loading-bar :value="loading"></loading-bar>
    <v-expand-transition>
      <div v-if="playVideo">

        <v-btn color="error white" small fab absolute right @click.stop="playVideo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-card-text>
          <youtube
            v-if="playVideo.provider === 'youtube'"
            :videoId="playVideo.videoId"
            player-width="100%"
          ></youtube>
          <vue-vimeo-player
            v-else
            v-bind="vimeoOptions"
          ></vue-vimeo-player>
          <div class="text-right">
            <div v-if="user && user.vbl">{{playVideo.videoUrl}}</div>
            <v-btn color="error" text class="xsmall" @click.stop="confirm = true" v-if="!hasNoUpload">Delete</v-btn>
          </div>
          <v-expand-transition>
            <v-container
              v-if="confirm"
              class="d-flex transition-fast-in-fast-out title v-card--reveal"
              style="height: 100%; z-index: 1000"
            >
              <v-card style="max-width: 300px">
                <v-card-text class="text-center">
                  Delete this video?
                  <v-btn color="success" class="mt-12" :loading="deleteing" @click.stop="deleteImage(playVideo.id)">Yes</v-btn>
                  <v-btn color="error" class="mt-12" @click.stop="confirm=false" :disabled="deleteing">No</v-btn>
                </v-card-text>
              </v-card>
            </v-container>
          </v-expand-transition>
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-container fluid :class="{'single-row': isSingleRow}">
      <v-row dense :justify="isCentered && (!images.length || isUploadOnly) ? 'center' : null">
        <template v-if="!isUploadOnly">
          <v-col
            v-for="image in displayImages"
            :key="image.id"
            :cols="isVertical && !isSingleRow ? 12 : !isVertical && !isSingleRow ? 6 : null"
            :sm="!isVertical && !isSingleRow ? 4 : null"
            :md="!isVertical && !isSingleRow ? 3 : null"
            :xl="!isVertical && !isSingleRow ? 2 : null"
            @click.stop="previewImage(image)"
          >
            <v-img
              :src="image.thumbnail"
              :aspect-ratio="16/9"
              class="grey lighten-2 elevation-1"
              style="z-index: 3"
            >
              <v-row class="fill-height ma-0"
                slot="placeholder"

                align="center"
                justify="center"

              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </v-img>
          </v-col>
        </template>
        <!-- YOUTUBE UPLOADER -->
        <v-col
          v-if="!hasNoUpload"
          :cols="isVertical && !isSingleRow ? 12 : !isVertical && !isSingleRow ? 6 : null"
          :sm="!isVertical && !isSingleRow ? 4 : null"
          :md="!isVertical && !isSingleRow ? 3 : null"
          :xl="!isVertical && !isSingleRow ? 2 : null"
        >
          <v-responsive :aspect-ratio="16/9">
            <v-card
              class="grey lighten-2"
              height="100%"
            >
              <v-row class="fill-height ma-0"

                align="center"
                justify="center"

              >
                <yt-dialog
                  ref="uploader"
                  :loading="uploading"
                  @save-click="uploadVideo"
                ></yt-dialog>
              </v-row>
            </v-card>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      :persistent="!showButtons"
      width="auto"
    >
      <v-card>
        <v-card-text ref="ct">
          <v-window v-model="window" touchless>
            <v-window-item
              v-for="(selected, i) in displayImages" :key="i"
            >
                <youtube
                  :videoId="selected.videoId"
                ></youtube>
                <v-expand-transition>
                  <v-container
                    v-if="confirm"
                    class="d-flex transition-fast-in-fast-out title v-card--reveal"
                    style="height: 100%;"
                  >
                    <v-card style="max-width: 300px">
                      <v-card-text class="text-center">
                        Delete this video?
                        <v-btn color="success" class="mt-12" :loading="deleteing" @click.stop="deleteImage(selected.id)">Yes</v-btn>
                        <v-btn color="error" class="mt-12" @click.stop="confirm=false" :disabled="deleteing">No</v-btn>
                      </v-card-text>

                    </v-card>
                  </v-container>
                </v-expand-transition>
            </v-window-item>

          </v-window>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions py-0>
          <v-row dense justify="center" style="min-height: 52px">
            <v-fab-transition>
              <v-btn
                v-if="showButtons && profile"
                fab small left absolute
                color="color3 color3Text--text"
                style="margin-top: 6px"
                @click.stop="profileAsk = true"
                :disabled="selected && selected.url === profilePic"
              >
                <v-icon>fas fa-user-circle</v-icon>
              </v-btn>
            </v-fab-transition>
            <template v-if="images.length > 1">
              <v-fab-transition>
                <v-btn v-if="showButtons" fab small color="color3Text color3--text" @click.stop="imageStep(true)">
                  <v-icon>fas fa-caret-left</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-fab-transition>
                <v-btn v-if="showButtons" fab small color="color3Text color3--text" @click.stop="imageStep()">
                  <v-icon>fas fa-caret-right</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
            <v-fab-transition>
              <v-btn v-if="showButtons" fab small right absolute color="white error--text" style="margin-top: 6px" @click.stop="confirm = true">
                <v-icon>fas fa-trash</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { getIdFromURL } from 'vue-youtube-embed'
import YtDialog from '@/components/Utils/YouTubeAddDialog.vue'
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  props: [
    'username',
    'oId',
    'vertical',
    'noPreview',
    'singleRow',
    'imgHeight',
    'imgWidth',
    'disabled',
    'cover',
    'noUpload',
    'center',
    'logo',
    'uploadOnly',
    'exclude',
    'images',
    'onUploadImage',
    'onDeleteImage',
    'loading',
    'deleteing',
    'uploading',
    'profilePic',
    'profile',
    'youtube',
    'active'
  ],
  data () {
    return {
      window: null,
      dialog: false,
      confirm: false,
      playVideo: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    iImages () {
      return !this.youtube ? this.images : this.images.map(m => {
        if (!m.videoUrl) {
          m.videoUrl = m.url
          m.videoId = getIdFromURL(m.videoUrl)
          m.error = false
        }
        return m
      })
    },
    showButtons () {
      return !this.confirm && !this.profileAsk
    },
    isVertical () {
      return this.vertical !== undefined && this.vertical !== false
    },
    isLogo () {
      return this.logo !== undefined && this.logo !== false
    },
    hasNoPreview () {
      return this.noPreview !== undefined && this.noPreview !== false
    },
    hasNoUpload () {
      return this.noUpload !== undefined && this.noUpload !== false
    },
    isSingleRow () {
      return this.singleRow !== undefined && this.singleRow !== false
    },
    isCentered () {
      return this.center !== undefined && this.center !== false
    },
    isUploadOnly () {
      return this.uploadOnly !== undefined && this.uploadOnly !== false
    },
    ratio () {
      return this.youtube ? 16 / 9 : this.width && this.height ? null : 1
    },
    width () {
      return this.imgWidth ? this.imgWidth : this.isSingleRow ? 192 : null
    },
    height () {
      return this.imgHeight ? this.imgHeight : this.isSingleRow ? 192 : null
    },
    displayImages () {
      if (!this.exclude) return this.images
      const x = typeof this.exclude === 'string' ? [this.exclude] : this.exclude
      return this.images.filter(f => !x.includes(f.url))
    },
    viewport () {
      return {
        vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      }
    },
    videoH () {
      // const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      // const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      const w = (window.innerWidth * 0.9) - 32
      return w * (9 / 16)
    },
    vimeoOptions () {
      return {
        videoId: this.playVideo.videoId,
        playerWidth: undefined,
        playerHeight: undefined,
        options: {
          responsive: true
        }
      }
    }
  },
  methods: {
    imageStep (back) {
      var i = this.window
      i = back ? i - 1 : i + 1
      if (i < 0) i = this.images.length - 1
      if (i >= this.images.length) i = 0
      this.previewImage(i)
    },
    loadImages () {
      this.$emit('load-images')
    },
    uploadVideo (videoInfo) {
      this.$emit('upload-video', videoInfo)
    },
    deleteImage (id) {
      this.$emit('delete-image', id)
    },
    previewImage (image) {
      if (this.disabled) return
      this.playVideo = image
    },
    cancel () {
      this.dialog = this.confirm = false
      this.playVideo = null
      this.$refs.uploader.cancel()
    },
    play () {
      console.log('play me')
    }
  },
  watch: {
    dialog: function (val) {
      this.confirm = false
    },
    active: function (val) {
      if (!val) this.playVideo = null
    },
    playVideo: function () {
      this.confirm = false
    }
  },
  mounted () {
    this.loadImages()
  },
  components: {
    YtDialog,
    vueVimeoPlayer
  }
}
</script>

<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
  }
  .v-card--reveal .v-card {
    opacity: 1;
  }
  .holder:before {
    content: "";
    display: block;
    padding-top: 100%;
    float: left;
  }
  .single-row {
    overflow-x: auto;
  }
  .single-row .row {
    padding-bottom: 10px
  }
  .single-row .flex {
    flex-grow: 0
  }
  .v-card--reveal-text {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 1;
    position: absolute;
    width: 100%;
    font-size: 2em !important;
  }
</style>

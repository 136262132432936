<template>
  <v-container fluid>
    <youtube-library
      v-if="!(videos.length === 0 && viewOnly)"
      ref="library"
      :images="videos"
      :loading="loading"
      :deleteing="deleteing"
      :uploading="uploading"
      @delete-image="deleteImage"
      @upload-video="uploadVideo"
      :youtube="true"
      :active="active"
      :noUpload="viewOnly"
    ></youtube-library>
    <div v-if="videos.length === 0 && viewOnly" class="text-center pa-3 pt-0">
      {{player.firstName}} hasn't uploaded any videos yet.
    </div>
  </v-container>
</template>

<script>
import YoutubeLibrary from '@/components/Utils/YouTubeLibrary'
export default {
  props: ['player', 'active', 'viewOnly'],
  data () {
    return {
      loading: false,
      loaded: false,
      deleteing: false,
      uploading: false,
      videos: []
    }
  },
  methods: {
    loadVideos () {
      if (this.loaded) return
      this.loading = true
      this.$VBL.player.videos.getAll(this.player.id)
        .then(r => {
          this.videos = r.data
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.loading = false })
    },
    deleteImage (id) {
      this.deleteing = true
      this.$VBL.player.images.delete(this.player.id, id)
        .then(r => {
          var i = this.videos.findIndex(f => f.id === id)
          this.videos.splice(i, 1)
          this.$refs.library.cancel()
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.deleteing = false
        })
    },
    uploadVideo (videoInfo) {
      if (this.uploading) return

      this.uploading = true
      this.$VBL.player.videos.post(this.player.id, videoInfo.id)
        .then(response => {
          this.videos.push(response.data)
          this.$refs.library.cancel()
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.uploading = false })
    }
  },
  watch: {
    active: 'loadVideos'
  },
  components: {
    YoutubeLibrary
  },
  mounted () {
    if (this.active) {
      this.loadVideos()
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :persistent="loading"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn fab color="grey" v-on="on">
        <v-icon color="grey lighten-3">fas fa-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="white">
        <v-toolbar-title>Add a Video</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon @click.stop="dialog = false" color="grey lighten-2"><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-window v-model="window" touchless>

          <v-window-item :value="0">
            <v-row dense>
              <v-col class="text-right">
                <img :src="logos.youtube.url" height="50px" width="auto" contain>
              </v-col>
              <v-col class="shrink d-flex justify-center align-center title">
                OR
              </v-col>
              <v-col>
                <img :src="logos.vimeo.url" height="50px" width="auto" contain>
              </v-col>
            </v-row>
            <div>Enter the full URL of the video from YouTube or Vimeo</div>
            <v-text-field
              label="Video URL"
              v-model="ytUrl"
              hint="Copy and paste the full URL including the http"
              persistent-hint
              :error-messages="error"
              @focus="() => { this.error = [] }"
            ></v-text-field>
          </v-window-item>

          <v-window-item :value="1">
            <v-img
              v-if="window === 1"
              :src="vData.thumbnail"
            ></v-img>
            <div class="title mb-2">Does this look right?</div>
            <div>(If it does click save if not click the left arrow to go back)</div>
          </v-window-item>
        </v-window>

      </v-card-text>
      <v-card-actions>
        <v-fab-transition>
          <v-btn
            v-if="window > 0"
            color="color3Text color3--text"
            fab small
            @click.stop="window = 0"
            :disabled="loading"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            :key="rightFab.key"
            :color="rightFab.color"
            fab small
            @click.stop="rightFab.click"
            :disabled="!ytUrl"
            :loading="loading || iLoading"
          >
            <v-icon>{{rightFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getIdFromURL } from 'vue-youtube-embed'
import logos from '@/classes/LogoUrls'

export default {
  props: ['loading'],
  data () {
    return {
      dialog: false,
      window: 0,
      ytUrl: null,
      iLoading: false,
      failed_image: false,
      vData: null,
      error: []
    }
  },
  computed: {
    logos () {
      return logos
    },
    rightFab () {
      return this.window === 0 ? {
        icon: 'fas fa-caret-right',
        color: 'color3 color3Text--text',
        key: 'next',
        click: () => {
          this.getVideoInfo()
        }
      } : {
        icon: 'fas fa-save',
        color: 'success white--text',
        key: 'save',
        click: () => { this.$emit('save-click', this.vData) }
      }
    },
    newVideoUrl () {
      return this.ytUrl && `https://img.youtube.com/vi/${getIdFromURL(this.ytUrl)}/maxresdefault.jpg`
    },
    test () {
      return this.ytUrl && this.getVideoInfo(this.ytUrl)
    }
  },
  methods: {
    cancel () {
      if (this.dialog) this.dialog = false
      this.window = 0
      this.ytUrl = null
      this.vData = null
    },
    onImgError () {
      console.log('onImgError')
      this.failed_image = true
    },
    parseVideo (url) {
      // - Supported YouTube URL formats:
      //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
      //   - http://youtu.be/My2FRPA3Gf8
      //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
      // - Supported Vimeo URL formats:
      //   - http://vimeo.com/25451551
      //   - http://player.vimeo.com/video/25451551
      // - Also supports relative URLs:
      //   - //player.vimeo.com/video/25451551
      // eslint-disable-next-line no-useless-escape
      url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
      var type = null
      if (RegExp.$3.indexOf('youtu') > -1) {
        type = 'youtube'
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
        type = 'vimeo'
      }

      return {
        type: type,
        id: RegExp.$6
      }
    },
    getVideoInfo () {
      this.iLoading = true
      var videoObj = this.parseVideo(this.ytUrl)
      console.log(videoObj)
      if (!videoObj.id) {
        this.error = ['The URL appears incomplete, please try again']
      } else {
        this.$VBL.player.videos.getDetails(this.ytUrl)
          .then(r => {
            if (r.data) {
              this.vData = r.data
              this.window = 1
            } else {
              this.error = ['We could not find a video at this URL, please try again']
            }
          })
          .catch(e => {
            console.log(e)
            this.error = ['We seem to run into an internal error, please try again']
          })
          .finally(() => {
            this.iLoading = false
          })
      }
    },
    async getVideoThumbnail (url) {
      var videoObj = this.parseVideo(url)
      if (videoObj.type === 'youtube') {
        return 'https://img.youtube.com/vi/' + videoObj.id + '/maxresdefault.jpg'
      } else if (videoObj.type === 'vimeo') {
        await this.$http.get('http://vimeo.com/api/v2/video/' + videoObj.id + '.json')
          .then(r => {
            return r.data[0].thumbnail_large
          })
      }
    }
  },
  watch: {
    dialog: function (v) {
      !v && this.cancel()
    },
    ytUrl: function (v) {
      this.error = []
    }
  }
}
</script>

<style scoped>

</style>
